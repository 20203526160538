<template>
  <WeModal v-on:close="onClose" modal-width="50">
    <div slot="body" class="custom-scrollbar">
      <h5 class="custom-card-title">
        <a v-if="competitor.url" v-bind:href="competitor.url" target="_blank">
          <i class="fas fa-external-link-alt"></i>
        </a>
        <span>{{ getCompetitorName }} | Fiyat Geçmişi</span>
      </h5>
      <hr />
      <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
          <a
            class="nav-link active"
            id="pills-home-tab"
            data-toggle="pill"
            href="#pills-home"
            role="tab"
            aria-controls="pills-home"
            aria-selected="true"
            >Grafik</a
          >
        </li>
        <li class="nav-item ml-2" role="presentation">
          <a
            class="nav-link"
            id="pills-profile-tab"
            data-toggle="pill"
            href="#pills-profile"
            role="tab"
            aria-controls="pills-profile"
            aria-selected="false"
            >Tablo</a
          >
        </li>
      </ul>
      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-home"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
        >
          <div id="chart">
            <apexchart :options="chartOptions" :series="series"></apexchart>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="pills-profile"
          role="tabpanel"
          aria-labelledby="pills-profile-tab"
        >
          <WeTable
            class="pr-3"
            v-bind:header="false"
            v-bind:fixed-head="true"
            v-bind:index="false"
            v-bind:allow-sort-index="false"
            v-bind:searchable="false"
            v-bind:limitable="false"
            v-bind:data="getData"
            v-bind:columns="columns"
          />
        </div>
      </div>
    </div>
  </WeModal>
</template>
<script>
import ApexCharts from "vue-apexcharts";
export default {
  name: "PriceHistory",
  props: {
    competitor: {
      default: () => {},
    },
    product: {
      default: () => {},
    },
  },
  components: {
    apexchart: ApexCharts,
  },
  data() {
    return {
      columns: [
        {
          name: "price",
          th: "Fiyat",
          type: "currency",
        },
        {
          name: "stock_status",
          th: "Stok Durumu",
          type: "stock_status",
        },
        {
          name: "created_at",
          th: "Güncelleme Tarihi",
          type: "datetime",
        },
      ],
      series: [
        {
          name: "Fiyat",
          data: [],
        },
        // {
        //   name: "Stok",
        //   data: [],
        // },
      ],
    };
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    setChartData(data) {
      const priceSerie = this.series.find((s) => s.name == "Fiyat");
      // const quantitySerie = this.series.find((s) => s.name == "Stok");

      if (priceSerie) {
        data.forEach((comp) => {
          priceSerie.data.push(comp.price);
          this.chartOptions.xaxis.categories.push(comp.createdAt);
        });
      }
    },
  },
  computed: {
    getFavicon() {
      if (this.competitor) {
        return helper.getFavicon(this.competitor.url);
      }
    },
    getCompetitorName() {
      let result = this.competitor.name;
      if (this.competitor.tag) {
        result += " / " + this.competitor.tag;
      }

      return result;
    },
    getCompetitorPrice() {
      return localization.getMoneyFormat(
        this.competitor.currency,
        this.competitor.price
      );
    },
    getCompetitorAvailability() {
      let icon = "fas fa-times-circle text-danger";

      if (this.competitor.availability) {
        icon = "fas fa-check-circle text-success";
      }

      return icon;
    },
    getLastUpdate() {
      const date = helper.timestampToDate(this.competitor.updatedAt);
      let result = new Date(Date.parse(date));
      let options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      };

      return result.toLocaleDateString("tr-TR", options);
    },
    getData() {
      let result = [];

      if (this.competitor.prices && this.competitor.prices.length) {
        result = this.competitor.prices.map((p) => {
          p.stock_status = p.availability;
          p.created_at = helper.timestampToDate(p.createdAt);
          p.currency_code = { code: p.currency };
          p.price = p.price ? p.price : "0.00";

          if (p.convertedPrice) {
            p.converted_price = p.price;
            p.converted_price_currency = p.currency;

            p.price = p.convertedPrice;
            p.currency_code.code = p.convertedCurrency;
          }

          return p;
        });
      }

      this.setChartData(result);

      return result.sort((a, b) => b.createdAt - a.createdAt);
    },
    chartOptions() {
      return {
        chart: {
          height: "100%",
          type: "area",
          locales: [
            {
              name: "tr",
              options: {
                months: [
                  "Ocak",
                  "Şubat",
                  "Mart",
                  "Nisan",
                  "Mayıs",
                  "Haziran",
                  "Temmuz",
                  "Ağustos",
                  "Eylül",
                  "Ekim",
                  "Kasım",
                  "Aralık",
                ],
                shortMonths: [
                  "Oca",
                  "Şub",
                  "Mar",
                  "Nis",
                  "May",
                  "Haz",
                  "Tem",
                  "Ağu",
                  "Eyl",
                  "Eki",
                  "Kas",
                  "Ara",
                ],
                days: [
                  "Pazar",
                  "Pazartesi",
                  "Salı",
                  "Çarşamba",
                  "Perşembe",
                  "Cuma",
                  "Cumartesi",
                ],
                shortDays: ["Paz", "Pzt", "Sal", "Çar", "Per", "Cum", "Cmt"],
                toolbar: {
                  exportToSVG: "SVG İndir",
                  exportToPNG: "PNG İndir",
                  exportToCSV: "CSV İndir",
                  menu: "Menü",
                  selection: "Seçim",
                  selectionZoom: "Seçim Yakınlaştır",
                  zoomIn: "Yakınlaştır",
                  zoomOut: "Uzaklaştır",
                  pan: "Kaydır",
                  reset: "Yakınlaştırmayı Sıfırla",
                },
              },
            },
          ],
          defaultLocale: "tr",
          toolbar: {
            show: true,
            tools: {
              download: false, // <== line to add
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          type: "datetime",
          categories: [],
        },
        yaxis: {
          labels: {
            formatter: (value) => {
              return localization.getMoneyFormat(this.product.currency, value);
            },
          },
        },
        tooltip: {
          x: {
            format: "dd/MM/yy HH:mm",
          },
          y: {
            formatter: (value) => {
              return localization.getMoneyFormat(this.product.currency, value);
            },
          },
        },
      };
    },
  },
};
</script>